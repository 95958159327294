import React from 'react';
import AllostechLogo from './logo/allostech.png';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles({
  img: {
    width: 'auto',
    height: 23,
  },
});

const LogoFull = () => {
  const classes = useStyles()
  return (
    <img className={classes.img} src={AllostechLogo} alt="ALLOSTECH Logo" />
  );
};

export default LogoFull;
