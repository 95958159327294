import {
  createBaseThemeOptions,
  createUnifiedTheme,
  genPageTheme,
  palettes,
  shapes,
} from '@backstage/theme';

const AllostechThemeDark = createUnifiedTheme({
  ...createBaseThemeOptions({
    palette: {
      ...palettes.dark,
      primary: {
        main: '#41ba7c',
      },
      secondary: {
        main: '#8766ac',
      },
      errorBackground: '#8c4351',
      warningBackground: '#8f5e15',
      infoBackground: '#585397',
      navigation: {
        background: '#12134b',
        indicator: '#8f5e15',
        color: '#d5d6db',
        selectedColor: '#ffffff',
        navItem: {
          hoverBackground: '#8766ac',
        },
        submenu: {
          background: '#8766ac',
        },
      },
    },
  }),
  defaultPageTheme: 'home',
  /* below drives the header colors */
  pageTheme: {
    home: genPageTheme({ colors: ['#12134b', '#12134b'], shape: shapes.wave }),
    documentation: genPageTheme({
      colors: ['#12134b', '#12134b'],
      shape: shapes.wave2,
    }),
    tool: genPageTheme({ colors: ['#12134b', '#12134b'], shape: shapes.round }),
    service: genPageTheme({ colors: ['#12134b', '#12134b'], shape: shapes.wave }),
    website: genPageTheme({ colors: ['#12134b', '#12134b'], shape: shapes.wave }),
    library: genPageTheme({ colors: ['#12134b', '#12134b'], shape: shapes.wave }),
    other: genPageTheme({ colors: ['#12134b', '#12134b'], shape: shapes.wave }),
    app: genPageTheme({ colors: ['#12134b', '#12134b'], shape: shapes.wave }),
    apis: genPageTheme({ colors: ['#12134b', '#12134b'], shape: shapes.wave }),
  },
});

export default AllostechThemeDark